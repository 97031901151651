<script lang="ts">
	import {
		Category,
		categoryAboutMap,
		categoryNameMap,
		categorySlugMap,
		categoryTextMap,
	} from "../../../../core/schema/Category.js";
	import racks from "../assets/images/solutions/racks.jpg?webp&h=280&imagetools";
	import cranes from "../assets/images/solutions/cranes.png?webp&h=280&imagetools";
	import robots from "../assets/images/solutions/robots.png?webp&h=280&imagetools";
	import vertinodeAi from "../assets/images/solutions/vertinode.jpg?webp&h=280&imagetools";
	import { solutionsPathMap } from "../../../../core/schema/paths/solutionsPathMap.js";
	import Card from "../../../shared/components/Card.svelte";
	import type { Product } from "../../../../core/schema/Product.js";
	import { getProductDetailPath } from "../../../../core/schema/paths/getProductDetailPath.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";

	export let categories: Category[];
	export let vertinodeAiProduct: Product;

	const { TENANT_LOCALE } = getGlobalSettings();

	const categoryImageMap: Record<Category, string> = {
		[Category.Cranes]: cranes,
		[Category.Racks]: racks,
		[Category.Robots]: robots,
		[Category.VertinodeAi]: vertinodeAi,
	};
</script>

<div class="flex flex-col gap-2 md:grid md:grid-cols-2 xl:grid-cols-4">
	{#each categories as category, index}
		<Card
			image={categoryImageMap[category]}
			title={categoryNameMap[category][TENANT_LOCALE]}
			description={categoryTextMap[category][TENANT_LOCALE]}
			urlSlug={category === Category.VertinodeAi
				? getProductDetailPath(category, vertinodeAiProduct)
				: `${solutionsPathMap[TENANT_LOCALE]}/${categorySlugMap[category][TENANT_LOCALE]}`}
			squaredImage={true}
			customButtonText={index > 0 ? categoryAboutMap[category][TENANT_LOCALE] : undefined}
		/>
	{/each}
</div>
