<script lang="ts">
	import translations from "./Partners.translations.json";
	import type { Partner } from "../../../core/schema/Partner.js";
	import PartnersCarousel from "./Sliders/PartnersCarousel.svelte";
	import { getSharedTranslate } from "../utils/getSharedTranslate.js";

	export let partners: Partner[];
	export let customTitle: string | null = null;

	const translate = getSharedTranslate(translations);
</script>

<div class="bg-primary rounded-lg px-0 py-12 lg:py-14">
	<h2 class="px-1 text-center text-white sm:px-10 lg:text-[2rem]">{customTitle ?? translate("efficiency")}:</h2>
	<div class="mt-8 flex flex-wrap items-center justify-center gap-8 sm:gap-x-12 sm:gap-y-10 lg:mt-12">
		<PartnersCarousel groupedPartners={partners} />
	</div>
</div>
